var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"scroll-save",staticClass:"gap-errors text-sm bg-gray-100 rounded-lg border-2 border-solid max-w-full flex flex-col items-stretch gap-1 overflow-hidden relative",class:{
    'gap-errors--loading': _vm.isLoading,
    'border-blue-400': !_vm.isError,
    'border-red-500': _vm.isError,
  },attrs:{"role":"alert"}},[_c('div',{staticClass:"p-4 flex items-center gap-4 justify-between"},[_c('div',{staticClass:"flex items-center gap-3"},[_c('svg',{staticClass:"flex-shrink-0 inline w-5 h-5",class:{
          'text-blue-600': !_vm.isError,
          'text-red-500': _vm.isError,
        },attrs:{"aria-hidden":"true","fill":"currentColor","viewBox":"0 0 20 20","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z","clip-rule":"evenodd"}})]),_c('span',{staticClass:"font-medium",class:{
          'text-blue-600': !_vm.isError,
          'text-red-500': _vm.isError,
        }},[_vm._v(" "+_vm._s(_vm.message)+" ")])]),_c('button',{staticClass:"inline-flex flex-shrink-0 items-center justify-between w-32 px-2 py-1.5 px-3 bg-blue-600 text-white-text font-medium leading-tight rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-auto select-none",class:{gap_error_btn: _vm.previewForm},on:{"click":function($event){return _vm.expandToggle()}}},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.expanded ? "Hide" : "Show")+" Gaps")]),_c('svg',{staticClass:"w-2.5 h-2.5 transform transition-all",class:{ '-rotate-180': _vm.expanded },attrs:{"width":"16","height":"16","viewBox":"0 0 16 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round"}})])])]),_c('transition',{attrs:{"name":"collapse"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.expanded),expression:"expanded"}]},[(_vm.isLoading)?_c('div',{staticClass:"gap_loader items-center justify-center p-4 flex w-full"},[_c('loader',{attrs:{"size":"9"}})],1):_vm._e(),_c('div',{staticClass:"timeline w-full"},[_c('div',{ref:"vis"})])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }